<template>
  <div class="sub-header-thin-div">
    <div :class="`${isMobile ? 'p-2' : 'p-10'}`">
      <div class="mt-5 box-white" v-if="transactionList">
        <div class="mt-5 d-flex">
          <span class="w-25" v-if="!isMobile">&nbsp;</span>
          <span :class="`font-bold ${isMobile ? 'font-15' : 'font-25'}`">{{ $t('sales_amount') }}</span>
          <span :class="`ml-auto font-bold ${isMobile ? 'font-15' : 'font-25'}`">{{ getPriceFormat(salesAmount) }}</span>
          <span class="w-25" v-if="!isMobile">&nbsp;</span>
        </div>
        <div class="mt-2 d-flex">
          <span class="w-25" v-if="!isMobile">&nbsp;</span>
          <span :class="`ml-5 ${isMobile ? 'font-15' : 'font-25'}`">{{ $t('handling_fee') }}</span>
          <span :class="`ml-auto ${isMobile ? 'font-15' : 'font-25'}`">{{ getPriceFormat(handlingFee) }}</span>
          <span class="w-25" v-if="!isMobile">&nbsp;</span>
        </div>
        <div class="mt-2 d-flex">
          <span class="w-25" v-if="!isMobile">&nbsp;</span>
          <span :class="`font-bold ${isMobile ? 'font-15' : 'font-25'}`">{{ $t('refunded') }}</span>
          <span :class="`ml-auto font-bold ${isMobile ? 'font-15' : 'font-25'}`">{{ getPriceFormat(refunded) }}</span>
          <span class="w-25" v-if="!isMobile">&nbsp;</span>
        </div>
        <div class="mt-2 d-flex">
          <span class="w-25" v-if="!isMobile">&nbsp;</span>
          <span :class="`ml-5 ${isMobile ? 'font-15' : 'font-25'}`">{{ $t('refund_fee') }}</span>
          <span :class="`ml-auto ${isMobile ? 'font-15' : 'font-25'}`">{{ getPriceFormat(refundFee) }}</span>
          <span class="w-25" v-if="!isMobile">&nbsp;</span>
        </div>
        <div class="my-3 d-flex">
          <span class="w-25" v-if="!isMobile">&nbsp;</span>
          <span :class="`font-bold ${isMobile ? 'font-15' : 'font-25'}`">{{ $t('payout_amount') }}</span>
          <span :class="`ml-auto font-bold ${isMobile ? 'font-15' : 'font-25'}`">{{ getPriceFormat(payoutAmount) }}</span>
          <span class="w-25" v-if="!isMobile">&nbsp;</span>
        </div>
        <div class="m-5 d-flex justify-content-end">
          <v-btn class="button-normal" @click="generateBillingDocument()">
            <span class="">{{ $t('invoice') }}</span>
          </v-btn>
          <v-btn class="button-normal ml-2" @click="getUserListTask()">
            <span class="">{{ $t('export_buyers') }}</span>
          </v-btn>
        </div>
      </div>
      <!-- Test PDF -->
      <vue-html2pdf
        :show-layout="false"
        :float-layout="true"
        :enable-download="false"
        :preview-modal="true"
        :paginate-elements-by-height="1900"
        :pdf-quality="2"
        :manual-pagination="false"
        :html-to-pdf-options="htmlToPdfOptions"
        pdf-format="a4"
        pdf-orientation="portrait"
        pdf-content-width="100%"
        @progress="onProgress"
        ref="html2Pdf">
        <section slot="pdf-content">
          <div class="d-flex flex-column" v-if="eventInfo && organizerInfo">
            <div class="d-flex justify-content-end mb-5">
              <img :src="assets.logo" :height="30" />
            </div>
            <div class="mt-5 font-15">Eventbox / Am Strassanger 2 / 86470 Thannhausen</div>
            <div class="margin-top-2rem">
              <p class="mb-0 font-12">{{ organizerInfo.name || "" }}</p>
              <p class="font-12">{{ organizerInfo.address || "" }}</p>
              <p class="font-12 mb-0" v-if="organizerInfo.taxCode">{{ $t('tax_code') }}: {{ organizerInfo.taxCode }}</p>
              <p class="font-12" v-if="organizerInfo.ustId">UST ID: {{ organizerInfo.ustId }}</p>
            </div>
            <div class="margin-top-2rem">
              <p class="font-15 font-bold">{{ $t('description') }}</p>
              <p class="mb-0 font-12">{{ $t('credit_for_commission_services_see_customer_number') }}: {{ zeroPad(myInfo.customerNumber) }}</p>
              <p class="font-12">{{ $t('invoice_number') }}: {{ zeroPad(eventInfo.invoiceNumber) }}</p>
              <p class="mt-3 mb-0 font-12">{{ eventInfo.name }}</p>
              <p class="font-12">{{ $t('for_the_period') }}: {{ getDateStringFromTimestamp(eventInfo.startAt, 'DD.MM.YYYY') }} - {{ getDateStringFromTimestamp(eventInfo.endAt, 'DD.MM.YYYY') }}</p>
            </div>
            <div class="margin-top-2rem">
              <div class="mt-5 d-flex justify-content-between">
                <span class="font-bold font-12">{{ $t('sales_amount') }}</span>
                <span class="font-bold font-12">{{ getPriceFormat(salesAmount) }}</span>
              </div>
              <div class="mt-2 d-flex justify-content-between">
                <span class="ml-5 font-12">{{ $t('handling_fee') }}</span>
                <span class="font-12">{{ getPriceFormat(handlingFee) }}</span>
              </div>
              <div class="mt-2 d-flex justify-content-between">
                <span class="ml-5 font-12">{{ $t('refunded') }}</span>
                <span class="font-12">{{ getPriceFormat(refunded) }}</span>
              </div>
              <div class="mt-2 d-flex justify-content-between">
                <span class="ml-5 font-12">{{ $t('refund_fee') }}</span>
                <span class="font-12">{{ getPriceFormat(refundFee) }}</span>
              </div>
              <div class="mt-5 d-flex justify-content-between">
                <span class="font-bold font-12">{{ $t('payout_amount') }}</span>
                <span class="font-bold font-12">{{ getPriceFormat(payoutAmount) }}</span>
              </div>
              <div class="mt-2 d-flex justify-content-between">
                <span class="ml-5 font-12">{{ $t('paid') }}</span>
                <span class="font-12">{{ getPriceFormat(paid) }}</span>
              </div>
              <div class="mt-2 d-flex justify-content-between">
                <span class="ml-5 font-12">{{ $t('pending') }}</span>
                <span class="font-12">{{ getPriceFormat(pending) }}</span>
              </div>
              <div class="mt-5 d-flex justify-content-between">
                <span class="font-bold font-12">{{ $t('not_paid') }}</span>
                <span class="font-bold font-12">{{ getPriceFormat(remaining) }}</span>
              </div>
            </div>
            <div class="margin-top-2rem font-12">
              {{ $t('the_handling_fee_includes_tax') }}
            </div>
            <div class="margin-top-2rem text-center font-12">
              {{ $t('credit_made_in_few_days') }}
            </div>
            <div style="margin-top: 4rem;">
              <p class="font-15 font-bold">{{ $t('payouts') }}</p>
              <table class="w-100">
                <thead>
                  <tr>
                    <th style="width: 30%;" class="font-12">No.</th>
                    <th style="width: 40%;" class="font-12">{{ $t('payout_amount') }}</th>
                    <th style="width: 30%;" class="font-12">{{ $t('post_date') }}</th>
                  </tr>
                </thead>
                <tbody v-if="withdrawList && withdrawList.length">
                  <tr v-for="(item, index) in withdrawList" :key="index">
                    <td class="font-12 pt-2">{{ index + 1 }}</td>
                    <td class="font-12 pt-2 font-bold">{{ getPriceFormat(item.withdrawAmount) }}</td>
                    <td class="font-12 pt-2">{{  item.paidAt !== null ? getDateStringFromTimestamp(item.paidAt) : $t('pending') }}</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div style="margin-top: 9rem;">
              <table class="w-100">
                <thead>
                  <tr>
                    <th style="width: 33%;" class="font-12 font-bold">EVENTBOX</th>
                    <th style="width: 40%;" class="font-12 font-bold">Bankverbindung:</th>
                    <th style="width: 30%;" class="font-12 font-bold">Gerichtsstand</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="font-12 pt-1">Mail: info@eventboxde.com</td>
                    <td class="font-12 pt-1">Sparkasse Guenzburg-Krumbach</td>
                    <td class="font-12 pt-1">Memmingen</td>
                  </tr>
                  <tr>
                    <td class="font-12 pt-1"></td>
                    <td class="font-12 pt-1">IBAN: DE50 7205 1840 0040 7969 55</td>
                    <td class="font-12 pt-1">HRB 20618</td>
                  </tr>
                  <tr>
                    <td class="font-12 pt-1"></td>
                    <td class="font-12 pt-1">BIC: BYLADEM1GZK</td>
                    <td class="font-12 pt-1">Steuernummer: 151/125/90053</td>
                  </tr>
                  <tr>
                    <td class="font-12 pt-1"></td>
                    <td class="font-12 pt-1"></td>
                    <td class="font-12 pt-1">UST ID: DE361515286</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </section>
      </vue-html2pdf>
      <!-- End Test PDF-->
      <div class="mt-5 box-white" v-if="transactionList">
        <LineChartGenerator
          class="mt-2"
          :style="chartStyle"
          :chart-data="chartData1"
          :chart-options="chartOptions1"
          v-if="transactionList.length"
        />
        <div class="my-10 py-10 center" v-else>
          <p>{{ $t('no_data_found') }}</p>
        </div>
      </div>
      <div class="my-30 py-40 center" v-else>
        <b-spinner type="grow"></b-spinner>
        <p class="mt-5">{{ $t('alert_please_wait') }}</p>
      </div>
      <div class="mt-5 box-white" v-if="orderList">
        <div class="vertical-center">
          <span class="font-15">{{ `${$t('check_ins')}:` }}</span>
          <span class="ml-2 font-15 font-bold">{{ getDecimalFormat(checkIns) }}</span>
        </div>
        <LineChartGenerator
          class="mt-2"
          :style="chartStyle"
          :chart-data="chartData2"
          :chart-options="chartOptions2"
          v-if="orderList.length"
        />
        <div class="my-10 py-10 center" v-else>
          <p>{{ $t('no_data_found') }}</p>
        </div>
      </div>
      <div class="my-30 py-40 center" v-else>
        <b-spinner type="grow"></b-spinner>
        <p class="mt-5">{{ $t('alert_please_wait') }}</p>
      </div>
      <div class="mt-5" v-if="ticketList">
        <div class="mt-2 box-white" v-for="(item, index) in ticketList" :key="(item, index)">
          <span class="font-15 font-bold">{{ item.name }}</span>
          <div class="row">
            <div class="col-sm-6 vertical-center">
              <div class="ml-auto mr-5 d-flex flex-column">
                <span class="ml-auto font-12 alpha-07">{{ $t('sold_tickets') }}</span>
                <span class="ml-auto mt-2 font-15">{{ `${item.soldQuantity} / ${item.totalQuantity}` }}</span>
              </div>
              <RadialProgressBar
                :completed-steps="item.soldQuantity"
                :total-steps="item.totalQuantity"
                :diameter="60"
                :innerStrokeWidth="4"
                :strokeWidth="4"
                innerStrokeColor="#D3D3D3"
                start-color="#4B104F"
                stop-color="#4B104F"
              >
                <span class="font-15 font-bold" style="color: #4B104F;">{{ item.soldPercent }}</span>
              </RadialProgressBar>
            </div>
            <div class="col-sm-6 vertical-center">
              <div class="ml-auto mr-5 d-flex flex-column">
                <span class="ml-auto font-12 alpha-07">{{ $t('check_ins') }}</span>
                <span class="ml-auto mt-2 font-15">{{ `${item.usedQuantity} / ${item.soldQuantity}` }}</span>
              </div>
              <RadialProgressBar
                :completed-steps="item.usedQuantity"
                :total-steps="item.soldQuantity"
                :diameter="60"
                :innerStrokeWidth="4"
                :strokeWidth="4"
                inner-display="slot"
                innerStrokeColor="#D3D3D3"
                start-color="#4B104F"
                stop-color="#4B104F"
              >
                <span class="font-15 font-bold" style="color: #4B104F;">{{ item.usedPercent }}</span>
              </RadialProgressBar>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
  .margin-top-2rem {
    margin-top: 2rem;
  }
  .margin-top-3rem {
    margin-top: 3rem;
  }
  .font-normal {
    font-weight: normal;
  }
</style>

<script>
import { Line as LineChartGenerator } from 'vue-chartjs/legacy';
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement
} from 'chart.js';

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement
);

import RadialProgressBar from 'vue-radial-progress';
import parsePhoneNumber from 'libphonenumber-js';
import VueHtml2pdf from 'vue-html2pdf';

import { firestore } from '../../../main';
import { onSnapshot, getDocs, query, collection, where } from 'firebase/firestore';
import { getDecimalFormat, getPriceFormat, getDateStringFromTimestamp, showLoading, toFileName, getCurDateString, zeroPad } from '../../../functions';

import logo from '@/assets/images/logo_for_invoice.png';

export default {
  name: 'EventDashboard',
  components: {
    LineChartGenerator,
    RadialProgressBar,
    VueHtml2pdf
  },
  computed: {
    isMobile() {
      return this.$store.state.isMobile;
    },
    myInfo() {
      return this.$store.state.myInfo;
    },
    eventList() {
      return this.$store.state.eventList;
    },
    chartStyle() {
      return {
        height: `${this.isMobile ? 200 : 400}px`,
        position: 'relative'
      };
    },
  },
  watch: {
    eventList() {
      this.checkEvent();
    }
  },
  data() {
    return {
      chartData1: {
        labels: [],
        datasets: [
          {
            label: this.$t('sales_amount'),
            backgroundColor: '#4B104F',
            borderColor: '#4B104F',
            borderWidth: 1,
            cubicInterpolationMode: 'monotone',
            data: []
          }
        ]
      },
      chartOptions1: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          y: {
            ticks: {
              callback: function(value, index, ticks) {
                return getPriceFormat(value, true);
              }
            }
          }
        },
        plugins: {
          tooltip: {
            callbacks: {
              label: function(context) {
                let label = context.dataset.label || '';
                if (label) {
                    label += ': ';
                }
                if (context.parsed.y !== null) {
                    label += getPriceFormat(context.parsed.y);
                }
                return label;
              }
            }
          }
        }
      },
      chartData2: {
        labels: [],
        datasets: [
          {
            label: this.$t('check_ins'),
            backgroundColor: '#4B104F',
            borderColor: '#4B104F',
            borderWidth: 1,
            cubicInterpolationMode: 'monotone',
            data: []
          }
        ]
      },
      chartOptions2: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          y: {
            ticks: {
              // Include a dollar sign in the ticks
              callback: function(value, index, ticks) {
                return getDecimalFormat(value);
              }
            }
          }
        }
      },
      assets: {
        logo
      },
      htmlToPdfOptions: {
        margin: 10,
        filename: 'Invoice',
        image: {
          type: 'jpeg',
          quality: 1.0
        },
        html2canvas: {
          scale: 1,
          useCORS: true
        }
      },
      salesAmount: 0,
      handlingFee: 0,
      refundedAmount: 0,
      refundFee: 0,
      payoutAmount: 0,
      checkIns: 0,
      paid: 0,
      pending: 0,
      remaining: 0,
      eventInfo: null,
      transactionList: null,
      ticketList: null,
      orderList: null,
      snapTransactionList: null,
      snapOrderList: null,
      snapTicketList: null,
      organizerInfo: null,
      snapWithdrawList: null,
      loader: null,
      withdrawList: []
    };
  },
  mounted() {
    if (this.$route.params.eventId) {
      if (this.myInfo) {
        if (this.myInfo.userType === this.USER_ORGANIZER) {
          this.getTransactionListTask();
          this.getOrderListTask();
          this.getTicketListTask();
          this.checkEvent();
        } else {
          this.$toast.error('No permission!!');
          this.$router.push('/');
        }
      } else {
        this.$toast.info(this.$t('alert_sign_in'));
        this.$router.push('/');
      }
      this.getWithdrawListTask();
    } else {
      this.$router.push('/');
    }
  },
  destroyed() {
    if (this.snapTransactionList) {
      this.snapTransactionList();
    }
    if (this.snapOrderList) {
      this.snapOrderList();
    }
    if (this.snapTicketList) {
      this.snapTicketList();
    }
    if (this.snapWithdrawList) {
      this.snapWithdrawList();
    }
  },
  methods: {
    getDecimalFormat,
    getPriceFormat,
    zeroPad,
    getDateStringFromTimestamp,
    checkEvent() {
      if (this.eventList) {
        this.eventInfo = this.eventList.find(element => element.eventId === this.$route.params.eventId);
        if (this.eventInfo) {
          if (this.eventInfo.userId !== this.$store.state.myId) {
            this.$toast.error('This is not your event!!');
            this.$router.push('/my_events');
          }
        } else {
          this.$toast.error('Invalid event!');
          this.$router.push('/my_events');
        }
      }
    },
    generateTransactionChartData() {
      const labels = [];
      const data = [];
      var salesAmount = 0;
      var handlingFee = 0;
      var refunded = 0;
      var refundFee = 0;
      var value = 0;
      this.transactionList.forEach(info => {
        const date = getDateStringFromTimestamp(info.createdAt, 'DD.MM.YYYY');
        if (!labels.includes(date)) {
          if (labels.length > 0) {
            data.push(value);
            value = 0;
          }
          labels.push(date);
        }
        const amount = info.salesAmount || 0;
        value += amount;
        salesAmount += amount;
        handlingFee += info.handlingFee || 0;
        if (info.refundRequestedAt) {
          refunded += amount;
          refundFee += info.refundFee || 0;
        }
      });
      data.push(value);
      this.salesAmount = salesAmount;
      this.handlingFee = handlingFee;
      this.refunded = refunded;
      this.refundFee = refundFee;
      this.payoutAmount = salesAmount - handlingFee - refunded - refundFee;
      this.chartData1.labels = labels;
      this.chartData1.datasets[0].data = data;
    },
    generateOrderChartData() {
      const labels = [];
      const data = [];
      var checkIns = 0;
      var value = 0;
      this.orderList.forEach(info => {
        const date = getDateStringFromTimestamp(info.usedAt, 'DD.MM.YYYY HH:00');
        if (!labels.includes(date)) {
          if (labels.length > 0) {
            data.push(value);
            value = 0;
          }
          labels.push(date);
        }
        checkIns++;
        value++;
      });
      data.push(value);
      this.checkIns = checkIns;
      this.chartData2.labels = labels;
      this.chartData2.datasets[0].data = data;
    },
    exportBuyers(buyerList) {
      if (!this.eventInfo || buyerList.length < 1) {
        return;
      }
      var csvContent = 'data:text/csv;charset=utf-8,Name,Email,Phone';
      buyerList.forEach(info => {
        const phoneNumber = info.phone ? parsePhoneNumber(info.phone, 'DE') : null;
        csvContent += `\n${info.name},${info.email},${phoneNumber ? phoneNumber.formatInternational() : ''}`;
      });
      const encodedUri = encodeURI(csvContent);
      const link = document.createElement('a');
      link.setAttribute('href', encodedUri);
      link.setAttribute('download', `${toFileName(this.eventInfo.name)}_Exportieren_${getCurDateString()}.csv`);
      document.body.appendChild(link);
      link.click();
    },
    generateBillingDocument() {
      const myInfo = this.myInfo;
      this.organizerInfo = myInfo.organizerInfo;
      if (!this.organizerInfo || !this.eventInfo) {
        this.$toast.error(this.$t('alert_not_valid_event'));
        return;
      }
      this.loader = showLoading(this);
      this.htmlToPdfOptions.filename = `${toFileName(this.eventInfo.name)}_Rechnung_${getCurDateString()}`;
      this.$refs.html2Pdf.generatePdf();
    },
    onProgress(progress) {
      if (progress === 100 && this.loader) {
        this.loader.hide();
        this.loader = null;
      }
    },
    getWithdrawListTask() {
      if (this.snapWithdrawList) {
        return;
      }
      const q = query(collection(firestore, 'withdraw'), where('eventId', '==', this.$route.params.eventId));
      this.snapWithdrawList = onSnapshot(q, querySnapshot => {
        const withdrawList = [];
        querySnapshot.forEach(doc => withdrawList.push(doc.data()));
        withdrawList.sort((a, b) => (a.requestedAt.seconds > b.requestedAt.seconds) ? 1 : -1);
        this.withdrawList = withdrawList;
        this.withdrawList.forEach(info => {
          if (info.paidAt === null) {
            this.pending += info.withdrawAmount;
          } else {
            this.paid += info.withdrawAmount;
          }
        });
        this.remaining = this.payoutAmount - this.paid - this.pending;
      });
    },
    getTransactionListTask() {
      if (this.snapTransactionList) {
        return;
      }
      const q = query(collection(firestore, 'transaction'), where('eventId', '==', this.$route.params.eventId));
      this.snapTransactionList = onSnapshot(q, querySnapshot => {
        const transactionList = [];
        querySnapshot.forEach(doc => transactionList.push(doc.data()));
        transactionList.sort((a, b) => (a.createdAt.seconds > b.createdAt.seconds) ? 1 : -1);
        this.transactionList = transactionList;
        this.generateTransactionChartData();
      });
    },
    getOrderListTask() {
      if (this.snapOrderList) {
        return;
      }
      const q = query(collection(firestore, 'order'), where('usedAt', '!=', null), where('eventId', '==', this.$route.params.eventId));
      this.snapOrderList = onSnapshot(q, querySnapshot => {
        const orderList = [];
        querySnapshot.forEach(doc => orderList.push(doc.data()));
        orderList.sort((a, b) => (a.usedAt.seconds > b.usedAt.seconds) ? 1 : -1);
        this.orderList = orderList;
        this.generateOrderChartData();
      });
    },
    getTicketListTask() {
      if (this.snapTicketList) {
        return;
      }
      const q = query(collection(firestore, 'ticket'), where('eventId', '==', this.$route.params.eventId));
      this.snapTicketList = onSnapshot(q, querySnapshot => {
        const ticketList = [];
        var soldQuantitySum = 0;
        var usedQuantitySum = 0;
        var totalQuantitySum = 0;
        querySnapshot.forEach(doc => {
          const data = doc.data();
          const soldQuantity = data.soldQuantity || 0;
          const usedQuantity = data.usedQuantity || 0;
          const totalQuantity = data.totalQuantity || 0;
          soldQuantitySum += soldQuantity;
          usedQuantitySum += usedQuantity;
          totalQuantitySum += totalQuantity;
          ticketList.push({
            name: data.name,
            soldQuantity: soldQuantity,
            usedQuantity: usedQuantity,
            totalQuantity: totalQuantity,
            soldPercent: totalQuantity > 0 ? `${Math.round(soldQuantity / totalQuantity * 100)}%` : '0%',
            usedPercent: soldQuantity > 0 ? `${Math.round(usedQuantity / soldQuantity * 100)}%` : '0%'
          });
          ticketList.sort((a, b) => (b.totalQuantity > 0 ? b.soldQuantity / b.totalQuantity : 0) > (a.totalQuantity > 0 ? a.soldQuantity / a.totalQuantity : 0) ? 1 : -1)
        });
        const totalInfo = {
          name: this.$t('total'),
          soldQuantity: soldQuantitySum,
          usedQuantity: usedQuantitySum,
          totalQuantity: totalQuantitySum,
          soldPercent: totalQuantitySum > 0 ? `${Math.round(soldQuantitySum / totalQuantitySum * 100)}%` : '0%',
          usedPercent: soldQuantitySum > 0 ? `${Math.round(usedQuantitySum / soldQuantitySum * 100)}%` : '0%'
        };
        this.ticketList = [totalInfo].concat(ticketList);
      });
    },
    getUserListTask() {
      if (!this.transactionList) {
        return;
      }
      const promises = [];
      var idList = [];
      this.transactionList.forEach(info => {
        if (!idList.includes(info.buyerId)) {
          idList.push(info.buyerId);
        }
        if (idList.length === 10) {
          promises.push(getDocs(query(collection(firestore, 'user'), where('userId', 'in', idList))));
          idList = [];
        }
      });
      if (idList.length > 0) {
        promises.push(getDocs(query(collection(firestore, 'user'), where('userId', 'in', idList))));
      }
      const loader = showLoading(this);
      Promise.all(promises).then(snapshots => {
        loader.hide();
        const buyerList = [];
        for (const snap of snapshots) {
          for (const doc of snap.docs) {
            buyerList.push({
              name: doc.get('userName'),
              email: doc.get('email'),
              phone: doc.get('phone')
            });
          }
        }
        this.exportBuyers(buyerList);
      });
    }
  }
};
</script>